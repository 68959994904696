/* eslint-disable @next/next/no-sync-scripts */
import { NextSeo } from "next-seo";

import { CacheProvider, EmotionCache } from "@emotion/react";
import { LicenseInfo } from "@mui/x-license-pro";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/az";
import "dayjs/locale/en";
import "dayjs/locale/ru";

import { NextPage } from "next";
import { IntlErrorCode, IntlProvider as NextIntlProvider } from "next-intl";

import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import { QueryClientProvider } from "react-query";

import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { ThemeProvider } from "../fe-ui/theme/ThemeProvider";
import createEmotionCache from "../server/utils/createEmotionCache";
import { MotionLazyContainer } from "../ui/components/animate/MotionLazyContainer";

import SnackbarProvider from "../fe-ui/blocks/Snackbar/SnackbarProvider";

import "mapbox-gl/dist/mapbox-gl.css";
import "slick-carousel/slick/slick.css";
import "../ui/components/Map/map.css";
import "../ui/index.global.css";

import { useRouter } from "next/router";
import { IntlMessages } from "../../global";
import { queryClient } from "../fe-ui/utils/request-query-client";
import { LongStayHandleWidgetLazy } from "../ui/widgets/LongStayHandleWidget/LongStayHandleWidgetLazy";
import { ContactWidgetLazy } from "../ui/widgets/ContactWidget/ContactWidgetLazy";
import { YMInitializer } from "../ui/components/YMInitializer/YMInitializer";
import { ThemeMode } from "../fe-ui/theme/palette";
import { EmbeddedContext } from "../ui/EmbeddedContext";
import { useMediaQuery, useTheme } from "@mui/material";
import { env } from "../fe-ui/utils/env";

// TODO: Env
LicenseInfo.setLicenseKey(
  "4a6c99ac4d35088ed61d380da9977f30Tz02NTk5NSxFPTE3MTUxODc1NTQxNTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, props: any) => ReactNode;
};

export type PageProps = {
  themeMode?: ThemeMode;
  basePath?: string;
  messages: IntlMessages;
  now: number;
  seo?: {
    title?: string;
    description?: string;
    keywords?: string;
  };
};

type Props = Omit<AppProps, "pageProps"> & {
  pageProps: PageProps;
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout<PageProps>;
};

const clientSideEmotionCache = createEmotionCache();

function getMessageFallback({ namespace, key, error }) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
}

function App({ Component, pageProps, ...props }: Props) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const { emotionCache = clientSideEmotionCache } = props;
  const getLayout = Component.getLayout ?? ((page) => page);
  const { locale, pathname, asPath } = useRouter();
  const isHome = pathname === "/";
  const isSearchResultsPage = pathname === "/search";

  useEffect(() => {
    if (!document.querySelector('link[href="https://api.tiles.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css"]')) {
      // Lazy loading mapbox css for SEO reasons
      const mapboxCSS = document.createElement("link");
      mapboxCSS.href = "https://api.tiles.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css";
      mapboxCSS.rel = "stylesheet";
      document.head.appendChild(mapboxCSS);
    }
  }, []);

  return (
    <NextIntlProvider
      getMessageFallback={getMessageFallback}
      messages={pageProps.messages}
      now={new Date(pageProps.now)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      locale={locale as any}
      timeZone="Asia/Baku"
    >
      <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
        <CacheProvider value={emotionCache}>
          {pageProps.seo && <NextSeo title={pageProps.seo.title} description={pageProps.seo.description} />}
          <Head>
            <link rel="canonical" href={`${env("PUBLIC_ORIGIN")}/${locale}${asPath}`} />
            <meta name={"viewport"} content="initial-scale=1, maximum-scale=1, width=device-width" />
            <meta name="google" content="notranslate" />
            <script src={"/api/env-js"} type={"text/javascript"} />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.__LOCALE__='${locale}';
                window.__MESSAGES__=${JSON.stringify(pageProps.messages)};
                `,
              }}
            />
          </Head>

          <YMInitializer />
          <EmbeddedContext.Provider
            value={{
              basePath: pageProps.basePath,
              themeMode: pageProps.themeMode,
            }}
          >
            <ThemeProvider themeMode={pageProps.themeMode}>
              <RecoilRoot>
                <RecoilNexus />
                <MotionLazyContainer>
                  <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>{getLayout(<Component {...pageProps} />, pageProps)}</SnackbarProvider>
                  </QueryClientProvider>
                </MotionLazyContainer>

                <LongStayHandleWidgetLazy />
                {/* Condition needed so that the widget does not conflict with sticky search */}
                {!((isHome || isSearchResultsPage) && isXS && pageProps.themeMode !== ThemeMode.aztravel) && (
                  <ContactWidgetLazy type="speeddial" />
                )}
              </RecoilRoot>
            </ThemeProvider>
          </EmbeddedContext.Provider>
        </CacheProvider>
      </LocalizationProvider>
    </NextIntlProvider>
  );
}

export default App;
