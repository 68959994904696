/* eslint-disable @next/next/next-script-for-ga */
"use client";
import Head from "next/head";

import { useEffect } from "react";

const getYmAccount = () =>
  typeof window !== "undefined" ? Number(window["__ENV__"]["NEXT_PUBLIC_YANDEX_METRIKA_PROJECT_ID"]) : 0;

export const emitYMAnalytics = (event: string, payload?: any) => {
  window["ym"]?.(getYmAccount(), "reachGoal", event);

  window["dataLayer"]?.push({
    event,
    ...payload,
  });
};

export const getYmClientId = (): Promise<string> => {
  return new Promise((resolve) => {
    try {
      window["ym"](getYmAccount(), "getClientID", resolve);
    } catch (_e) {
      resolve("");
    }
  });
};

export const YMInitializer = () => {
  const account = getYmAccount();

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const clickHandler = (e) => {
      for (let t = e.target; t && t !== document; t = t.parentElement) {
        if (t.dataset.ym) {
          const eventName = `CLICK_${t.dataset.ym.toUpperCase()}`;

          emitYMAnalytics(eventName);
          return;
        }
      }
    };

    const focusHandler = (e) => {
      for (let t = e.target; t && t !== document; t = t.parentElement) {
        if (t.name) {
          const eventName = `FOCUS_${t.name.toUpperCase()}`;

          emitYMAnalytics(eventName);
          return;
        }
      }
    };

    // const submitHandler = (e) => {
    //   for (let t = e.target; t && t !== document; t = t.parentElement) {
    //     if (t.tagName === 'FORM') {
    //       const eventName = `SUBMIT_${t.name.toUpperCase()}`;
    //       window["ym"]?.(account, "reachGoal", eventName);
    //       pushGtmEvent(eventName, {});
    //       return;
    //     }
    //   }
    // };

    window.addEventListener("click", clickHandler, true);
    window.addEventListener("focus", focusHandler, true);
    // window.addEventListener("submit", submitHandler, true);
    return () => {
      window.removeEventListener("click", clickHandler);
      window.removeEventListener("focus", focusHandler);
      // window.removeEventListener("submit", submitHandler);
    };
  }, []);

  if (typeof window === "undefined") {
    return null;
  }

  if (!account || isNaN(account) || typeof window === "undefined") {
    return null;
  }

  return (
    <>
      <Head>
        {/*  */}

        {/* <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${account}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        ecommerce:"dataLayer"
   });
     `,
          }}
        /> */}

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_KEY}');`,
          }}
        />
      </Head>

      {/* <noscript>
        <div>
          <img src={`https://mc.yandex.ru/watch/${account}`} style={{ position: "absolute", left: "-9999px" }} alt="" />
        </div>
      </noscript> */}

      {process.env.NEXT_PUBLIC_GTM_KEY && (
        <>
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_KEY}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </>
      )}
    </>
  );
};
