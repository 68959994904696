export function makeUrl(
  url: string,
  data: any = {},
  options = {
    encodeKeys: false,
    encodeValues: false,
  }
) {
  const { encodeKeys, encodeValues } = options;
  return `${url}${Object.keys(data).length ? "?" : ""}${Object.entries(data)
    .map(([key, value]) => [
      encodeKeys ? encodeURIComponent(key) : key,
      encodeValues ? encodeURIComponent(value as string) : value,
    ])
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`.replace("??", "?");
}
