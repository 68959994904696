import { forwardRef } from "react";
import { Icon } from "@iconify/react";
import { IconifyIcon } from "@iconify/react";
import { Box, BoxProps } from "@mui/material";

export type IconifyProps = IconifyIcon | string;

export type Props = BoxProps & {
  icon: IconifyProps;
};

export const Iconify = forwardRef<SVGElement, Props>(function Iconify({ icon, width = 20, sx, ...other }, ref) {
  return <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />;
});

export default Iconify;
