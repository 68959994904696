import dynamic from "next/dynamic";
import { useRecoilValue } from "recoil";
import { dateRangeAtom } from "../../../state/search-state";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const LongStayHandleWidget = dynamic(
  () => import("./LongStayHandleWidget").then((x) => x.LongStayHandleWidget),
  {
    ssr: false,
  }
);

export const LongStayHandleWidgetLazy = () => {
  const range = useRecoilValue(dateRangeAtom);
  const [from, to] = range;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (from && to) {
      setOpen(dayjs(to).diff(from, "day") > 29);
    }
  }, [from, to]);

  if (open) {
    return <LongStayHandleWidget />;
  }
  return null;
};
